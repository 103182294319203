import { SiteOnView } from 'shared/domain/site/types/view';
import { entityToModelBase } from 'shared/domain/toBaseModel';
import { UserEntity } from 'shared/domain/user/types/entity';
import { UserWithPersonalData } from 'shared/domain/user/types/model';
import { SiteEntity } from '../types/entity';
import { SiteCreateModel, SiteEditModel, SiteModel } from '../types/model';

export function siteOnViewToSiteCreateModel(
  fields: SiteOnView,
  projectId: string,
  author?: UserWithPersonalData
): SiteCreateModel {
  return {
    ...fields,
    createdBy: author,
    modifiedBy: author,
    createdAt: fields.createdAt.toISO(),
    modifiedAt: fields.modifiedAt.toISO(),
    projectId,
  };
}
export function siteOnViewToSiteEditModel(
  fields: SiteOnView,
  projectId: string
): SiteEditModel {
  return {
    label: fields.label,
    code: fields.code,
    _id: fields._id,
    projectId,
  };
}

export function siteEntityToModel(
  entity: SiteEntity,
  users: UserEntity[]
): SiteModel {
  const modelBase = entityToModelBase({ entity, users });

  return {
    ...modelBase,
    code: entity.code,
    label: entity.label,
  };
}
