import { useState, useEffect } from 'react';

export const useDeviceType = (): { isTouch: boolean } => {
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    const touchDevice =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouch(touchDevice);
  }, []);

  return { isTouch };
};
