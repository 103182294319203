import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';
import VideocamIcon from '@mui/icons-material/Videocam';
import styles from './styles';
import {
  isVideo,
  isImage,
} from 'components/common/graphicUploader/helpers';
import { DrawingType } from '../graphicUploader/types';
import { StoreState } from 'setup/types/core';
import { useBlobFileStorage } from 'components/core/BlobFileStorage';
import { selectIcon } from '../../../views/issue/events/eventDetails/helpers';
import useIsMobile from '../../../hooks/useIsMobile';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import { useSelector } from 'react-redux';
import { useGalleryContext } from '.';
import Skeleton from '@mui/material/Skeleton';

type Props = {
  uniqueKey: string;
  index: number;
  drawing: DrawingType;
  isVisible: boolean;
  isActive: boolean;
};
const _Miniature: React.FC<Props> = ({
  uniqueKey,
  index,
  drawing,
  isVisible,
  isActive,
}) => {
  const icons = useSelector((store: StoreState) => store.icons.icons);
  const blobFileStorage = useBlobFileStorage();
  const isMobile = useIsMobile();
  const cs = styles({ isMobile });
  const { changeGallery, useDisplayedFiles, downloadFile } =
    useGalleryContext();
  const indexSelector: <T>(files: T[]) => T = useMemo(() => {
    return function fileAtIndexSelector<T>(files: T[]) {
      return files[index];
    };
  }, [index]);
  const [file] = useDisplayedFiles(indexSelector);

  useEffect(() => {
    if (isVisible) {
      downloadFile(file);
    }
  }, [isVisible, file, downloadFile]);

  const onClick = (): void => {
    changeGallery('slide', index);
  };

  const csActive = { [cs.active]: isActive };
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [file]);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  if (file.deleted) {
    return null;
  }

  if (file.loading) {
    return (
      <div
        style={{
          position: 'relative',
          width: '104px',
          height: '104px',
          margin: '5px',
        }}
      >
        <Skeleton
          className={cs.miniatureSkeleton}
          variant='rectangular'
          width={'104px'}
          height={'104px'}
        />
      </div>
    );
  }

  if (isVideo(file)) {
    return (
      <div className={cn(cs.video, csActive)} onClick={onClick}>
        <VideocamIcon />
      </div>
    );
  }

  if (isImage(file)) {
    const storedBlobUrl =
      (drawing?.mergedImageUrl &&
        blobFileStorage.getItem(drawing?.mergedImageUrl)) ||
      (file._id && blobFileStorage.getItem(file._id));

    return (
      <img
        // s3 + chrome - big issue around Origin and CORS - just use 'use-credentials' for every request to s3 PT-2785
        crossOrigin='use-credentials'
        className={cn(cs.video, cs.miniatureImage, csActive)}
        key={uniqueKey}
        src={
          error
            ? DOCUMENT_ERROR_SOURCE
            : // eslint-disable-next-line prettier/prettier
              (storedBlobUrl ??
              (drawing?.mergedImageUrl || file.thumbnail) ??
              // eslint-disable-next-line prettier/prettier
              file.src)
        }
        onClick={onClick}
        alt={file.description || file.title}
        onError={onError}
      />
    );
  }

  return (
    <div className={cn(cs.video, csActive)} onClick={onClick}>
      <img
        src={selectIcon(
          file.type,
          file.data?.extension,
          icons?.document.files
        )}
        alt={file.description || file.title}
      />
    </div>
  );
};

type MiniatureTextProps = {
  text?: string | null;
  isActive: boolean;
};
function _MiniatureText({
  text,
  isActive,
}: MiniatureTextProps): ReactElement {
  const cs = styles({ isMobile: false });
  const csActive = { [cs.activeText]: isActive };
  return <p className={cn(cs.miniatureText, csActive)}>{text || ' '}</p>;
}

const Miniature = memo(_Miniature);
const MiniatureText = memo(_MiniatureText);

export { Miniature, MiniatureText };
