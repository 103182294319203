import { Box, ClickAwayListener, List } from '@mui/material';
import { ReactComponent as CompanyNavigationIcon } from 'assets/icons/company.svg';
import { ReactComponent as ContractNavigationIcon } from 'assets/icons/contract.svg';
import { ReactComponent as AnalyticsNavigationIcon } from 'assets/icons/navigation_analytics.svg';
import { ReactComponent as BrowseInspectionNavigationIcon } from 'assets/icons/navigation_browseInspections.svg';
import { ReactComponent as InspectionNavigationIcon } from 'assets/icons/navigation_inspections.svg';
import { ReactComponent as IssueNavigationIcon } from 'assets/icons/navigation_issues.svg';
import { ReactComponent as ProjectPreviewNavigationIcon } from 'assets/icons/navigation_project_preview.svg';
import { ReactComponent as StartInspectionNavigationIcon } from 'assets/icons/navigation_startInspection.svg';
import { ReactComponent as UserNavigationIcon } from 'assets/icons/user.svg';
import { NavigationDestination } from 'helpers/navigation';
import useIsMobile from 'hooks/useIsMobile';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import {
  currentUserSelector,
  userEqualityFunction,
} from 'redux/selectors/users';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { isDefined } from 'shared/domain/role/isDefined';
import { isManagerOrAdmin } from 'shared/domain/role/isManagerOrAdmin';
import { isStandard } from 'shared/domain/role/isStandard';
import { userWithPermissionsInDtoToUserInDto } from 'shared/domain/user/mapping/toOutDto';
import { UserInDto } from 'shared/domain/user/types/inDto';
import {
  UserRole,
  UserWithAuthAndPermissions,
} from 'shared/types/userRole';
import { FrontVersion } from '../../index';
import { HeaderLogo } from '../NavigationHeaderLogo/HeaderLogo';
import NavigationItemButton from '../NavigationItem/NavigationItemButton';
import { DocumentationIcon } from './icons';
import useStyles, { drawerCollapsed, drawerExpanded } from './styles';
import { NavigationItemProps } from './types';
import { useDeviceType } from './useDeviceType';

export const NAVIGATION_DRAWER_ZINDEX = 1200;
const MemoIssueNavigationIcon = React.memo(IssueNavigationIcon);
const MemoAnalyticsNavigationIcon = React.memo(AnalyticsNavigationIcon);
const MemoBrowseInspectionNavigationIcon = React.memo(
  BrowseInspectionNavigationIcon
);
const MemoStartInspectionNavigationIcon = React.memo(
  StartInspectionNavigationIcon
);
const MemoInspectionNavigationIcon = React.memo(InspectionNavigationIcon);
const MemoCompanyNavigationIcon = React.memo(CompanyNavigationIcon);
const MemoContractNavigationIcon = React.memo(ContractNavigationIcon);
const MemoUserNavigationIcon = React.memo(UserNavigationIcon);
const MemoCurrentProjectNavigationIcon = React.memo(
  ProjectPreviewNavigationIcon
);
const MemoDocumentationIcon = React.memo(DocumentationIcon);

function ReactNavigationItem({
  navigationItemProps,
}: {
  navigationItemProps: NavigationItemProps;
}): ReactElement {
  const {
    visible,
    isSubItem,
    componentKey,
    redirectAddress,
    textId,
    Icon,
    isNavigationExpanded,
    disabled,
    setCollapsed,
  } = navigationItemProps;
  const history = useHistory();
  const { isTouch } = useDeviceType();
  const handleTouch = useCallback(
    (e: TouchEvent): void => {
      if (disabled) return;

      setCollapsed();
    },
    [disabled, isNavigationExpanded, setCollapsed]
  );

  const navItemRef = useRef<any>(null);

  const handleInteraction = useCallback(
    (e) => {
      if (disabled) return;
      if (redirectAddress) {
        history.push(redirectAddress);
      }
      setCollapsed();
    },
    [disabled, redirectAddress, history, setCollapsed]
  );

  if (redirectAddress) {
    return (
      <Box
        onTouchEnd={handleInteraction}
        onClick={handleInteraction}
        ref={navItemRef}
        // to={redirectAddress}
        sx={{
          pointerEvents: disabled ? 'none' : 'auto',
          display: visible ? 'block' : 'none',
          textDecoration: 'none',
        }}
        data-qa={`navigation-item-${componentKey}`}
        // onMouseMove={(e): void => {
        //   // fires after touch start which causes expanding drawer by parent
        //   e.stopPropagation();
        // }}
        key={componentKey}
      >
        <NavigationItemButton {...navigationItemProps} />
      </Box>
    );
  }

  return (
    <Box
      ref={navItemRef}
      sx={{
        pointerEvents: disabled ? 'none' : 'auto',
        display: visible ? 'block' : 'none',
      }}
      data-qa={`navigation-item-${componentKey}`}
      key={componentKey}
    >
      <NavigationItemButton {...navigationItemProps} />
    </Box>
  );
}

const NavigationDrawerPresentational = ({
  isNavigationExpanded,
  setNavigationExpanded,
}): ReactElement => {
  const { isTouch } = useDeviceType();
  const drawerRef = useRef<HTMLDivElement>(null);

  const setExpanded = useCallback(() => {
    if (!isTouch) {
      setNavigationExpanded(true);
    }
  }, [setNavigationExpanded, isTouch]);

  const setCollapsed = useCallback(() => {
    setNavigationExpanded(false);
  }, [setNavigationExpanded]);

  // Obsługa dotyku na całej nawigacji
  const handleDrawerTouch = useCallback(
    (e: TouchEvent) => {
      if (e.target === drawerRef.current) {
        e.preventDefault();
        setNavigationExpanded(!isNavigationExpanded);
      }
    },
    [isNavigationExpanded, setNavigationExpanded]
  );

  const handleLogoTouch = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();
      setNavigationExpanded(!isNavigationExpanded);
    },
    [isNavigationExpanded, setNavigationExpanded]
  );

  useEffect(() => {
    const drawer = drawerRef.current;

    if (!drawer || !isTouch) return;

    drawer.addEventListener('touchstart', handleDrawerTouch, {
      passive: false,
    });

    return () => {
      drawer.removeEventListener('touchstart', handleDrawerTouch);
    };
  }, [handleDrawerTouch, isTouch]);

  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const { _id: projectId } = useSelector(projectDataSelector);
  const currentUser: UserWithAuthAndPermissions = useSelector(
    currentUserSelector,
    userEqualityFunction
  );

  const currentUserDto: UserInDto = useMemo(() => {
    return userWithPermissionsInDtoToUserInDto(
      projectId,
      currentUser.data
    );
  }, [projectId, currentUser]);

  const isCurrentUserManager = isManagerOrAdmin(currentUserDto.role);
  const isCurrentUserAdmin = isAdmin(currentUserDto.role);
  function isAboveStandard(role: UserRole): boolean {
    return isDefined(role) && !isStandard(role);
  }
  const issues: NavigationItemProps = {
    visible: true,
    isSubItem: false,
    componentKey: NavigationDestination.issues,
    textId: 'navigation_drawer_item_issues',
    redirectAddress: '/issue',
    Icon: MemoIssueNavigationIcon,
    isNavigationExpanded,
    setCollapsed,
  };

  const inspections: NavigationItemProps = {
    visible: isAboveStandard(currentUserDto.role),
    isSubItem: false,
    componentKey: NavigationDestination.inspections,
    textId: 'navigation_drawer_item_inspections',
    Icon: MemoInspectionNavigationIcon,
    isNavigationExpanded,
    setCollapsed,
  };
  const inspectionTemplates: NavigationItemProps = {
    visible: isAboveStandard(currentUserDto.role),

    isSubItem: true,
    componentKey: NavigationDestination.browseInspectionTemplates,
    // PT-2191 adjust for release
    textId: 'temporary_key_navigation_templates',
    Icon: MemoStartInspectionNavigationIcon,
    redirectAddress: `/project/${projectId}/inspectionTemplate`,
    isNavigationExpanded,
    setCollapsed,
  };
  const browseInspections: NavigationItemProps = {
    visible: isAboveStandard(currentUserDto.role),

    isSubItem: true,
    componentKey: NavigationDestination.browseInspections,
    textId: 'navigation_drawer_item_browseInspections',
    Icon: MemoBrowseInspectionNavigationIcon,
    redirectAddress: '/inspection',
    isNavigationExpanded,
    setCollapsed,
  };
  const documentations: NavigationItemProps = {
    visible: true,
    isSubItem: false,
    componentKey: NavigationDestination.documentations,
    textId: 'general_documentation',
    redirectAddress: `/project/${projectId}/documentation`,
    Icon: MemoDocumentationIcon,
    isNavigationExpanded,
    setCollapsed,
    disabled: !projectId,
  };
  const analytics: NavigationItemProps = {
    visible: true,
    isSubItem: false,
    componentKey: NavigationDestination.analytics,
    textId: 'navigation_drawer_item_analytics',
    redirectAddress: '/analytics',
    Icon: MemoAnalyticsNavigationIcon,
    isNavigationExpanded,
    setCollapsed,
  };
  const companies: NavigationItemProps = {
    visible: true,
    isSubItem: false,
    componentKey: NavigationDestination.companies,
    textId: 'navigation_drawer_item_company',
    redirectAddress: '/company',
    Icon: MemoCompanyNavigationIcon,
    isNavigationExpanded,
    setCollapsed,
  };
  const contracts: NavigationItemProps = {
    visible: true,
    isSubItem: false,
    componentKey: NavigationDestination.contracts,
    textId: 'navigation_drawer_item_contract',
    redirectAddress: '/contract',
    Icon: MemoContractNavigationIcon,
    isNavigationExpanded,
    setCollapsed,
  };

  const users: NavigationItemProps = {
    visible: isCurrentUserManager || isCurrentUserAdmin,

    isSubItem: false,
    componentKey: NavigationDestination.users,
    textId: 'navigation_drawer_item_users',
    redirectAddress: '/user',
    Icon: MemoUserNavigationIcon,
    isNavigationExpanded,
    setCollapsed,
  };

  const projectSettings: NavigationItemProps = {
    visible: isCurrentUserAdmin,
    isSubItem: false,
    componentKey: NavigationDestination.projectSettings,
    textId: 'general_project',
    redirectAddress: '/projectSettings',
    Icon: MemoCurrentProjectNavigationIcon,
    isNavigationExpanded,
    setCollapsed,
  };

  return (
    <ClickAwayListener onClickAway={setCollapsed}>
      <Box
        ref={drawerRef}
        onMouseEnter={setExpanded}
        onMouseLeave={setCollapsed}
        id='navigationDrawer'
        sx={{
          zIndex: NAVIGATION_DRAWER_ZINDEX,
          position: 'absolute',
          top: 0,
          height: '100%',
          ...(isNavigationExpanded ? drawerExpanded : drawerCollapsed),
        }}
      >
        <Box onTouchStart={handleLogoTouch}>
          <HeaderLogo />
        </Box>

        <List className={classes.navigationButtonsWrapper}>
          <Box className='top-buttons'>
            <ReactNavigationItem navigationItemProps={issues} />
            <ReactNavigationItem navigationItemProps={inspections} />
            <ReactNavigationItem
              navigationItemProps={inspectionTemplates}
            />
            <ReactNavigationItem navigationItemProps={browseInspections} />
            <ReactNavigationItem navigationItemProps={documentations} />
            <ReactNavigationItem navigationItemProps={analytics} />
            <ReactNavigationItem navigationItemProps={companies} />
            <ReactNavigationItem navigationItemProps={contracts} />
            <ReactNavigationItem navigationItemProps={users} />
            <ReactNavigationItem navigationItemProps={projectSettings} />
          </Box>
        </List>
        <FrontVersion />
      </Box>
    </ClickAwayListener>
  );
};

export const NavigationDrawer = React.memo(NavigationDrawerPresentational);
